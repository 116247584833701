import React from 'react';
import { ProjectLayout } from '../layouts';

export default () => <ProjectLayout
    title='Candy Store'
    teaser='Team management tools'
    // note='Tech stack: Meteor, Django'
    year='2014'
>
    <img src='images/projects/b2b/dashboard.jpg' />
    <img src='images/projects/b2b/team.jpg' />
</ProjectLayout>;